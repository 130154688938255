
import './style.scss';

function NoData(props: any) {
	return (
		<div className='no-data'>
			<h1 className='txt-title'>No data found</h1>
		</div>
	);
}

export default NoData;